import React, { useState } from 'react';

import Layout from '../../components/Layout';

const NutritionTableGeneratorPage = () => {
  const [table, setTable] = useState([
    ['Typical values', 'Per 100g'],
    ['Energy', '100 kcal'],
  ]);

  const [clipboardString, setClipboardString] = useState(
    'Copy code to clipboard'
  );

  const handleClickButtonClearTable = () => {
    setTable([
      ['', ''],
      ['', ''],
    ]);
  };

  const handleChangeInput = (event) => {
    const _table = [...table];
    const [i, j] = event.target.name.split('_');
    _table[i][j] = event.target.value;
    setTable(_table);
  };

  const handleClickButtonAddRow = () => {
    let row = [];
    for (let i = 0; i < table[0].length; i++) {
      row[i] = '';
    }
    const _table = [...table, row];
    setTable(_table);
  };

  const handleClickButtonAddColumn = () => {
    const _table = [...table];
    for (let row of _table) {
      row.push('');
    }
    setTable(_table);
  };

  const handleClickButtonDeleteRow = (i) => {
    const _table = [...table];
    _table.splice(i, 1);
    setTable(_table);
  };

  const handleClickButtonDeleteColumn = (j) => {
    const _table = [...table];
    for (let row of _table) {
      row.splice(j, 1);
    }
    setTable(_table);
  };

  const handleClickButtonCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(table));
      setClipboardString('Copied!');
      setTimeout(() => {
        setClipboardString('Copy code to clipboard');
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className='box-content max-w-screen-xl mx-auto px-4 md:px-12 xl:px-36 w-[calc(100%-2rem)] md:w-[calc(100%-6rem)] xl:w-[calc(100%-18rem)]'>
        <div className='max-w-prose py-20'>
          <h2>Nutrition Table Generator</h2>
        </div>
        <div className='bg-white mb-10 pb-4 rounded'>
          <div className='pb-4 pl-12 pt-4'>
            <button
              onClick={handleClickButtonClearTable}
              className='border-2 border-danger flex font-bold h-12 items-center justify-center px-3 rounded text-center text-danger transition-colors duration-100 hover:bg-danger/5'
            >
              <span className='mr-2'>
                <svg viewBox='0 0 448 512' height='16'>
                  <path
                    d='M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <span>Clear table</span>
            </button>
          </div>
          <div className='flex'>
            <div className='mt-12'>
              {table.map((row, i) => (
                <button
                  key={i}
                  onClick={() => handleClickButtonDeleteRow(i)}
                  className='flex h-12 items-center justify-center mb-px text-danger disabled:text-disabled w-12'
                  disabled={table.length === 1}
                >
                  <svg viewBox='0 0 448 512' height='16'>
                    <path
                      d='M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z'
                      fill='currentColor'
                    />
                  </svg>
                </button>
              ))}
            </div>
            <div className='flex overflow-x-auto w-full'>
              <div className='flex-none'>
                <div className='flex'>
                  {table[0].map((column, j) => (
                    <div key={j} className='w-32'>
                      <button
                        onClick={() => handleClickButtonDeleteColumn(j)}
                        className='flex h-12 items-center justify-center mx-auto text-danger disabled:text-disabled w-12'
                        disabled={table[0].length === 1}
                      >
                        <svg viewBox='0 0 448 512' height='16'>
                          <path
                            d='M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z'
                            fill='currentColor'
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
                {table.map((row, i) => (
                  <div key={i} className='flex'>
                    {row.map((column, j) => (
                      <div
                        key={j}
                        className={`border-black/25 border-b ${
                          i === 0 ? 'border-t' : ''
                        } flex-none w-32`}
                      >
                        <input
                          onChange={handleChangeInput}
                          type='text'
                          name={`${i}_${j}`}
                          value={column}
                          className={`appearance-none bg-white ${
                            j === 0 ? 'border-l' : ''
                          } border-r border-black/25 h-12 outline-none p-2 transition-colors w-full focus:bg-olive/10`}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className='flex flex-none items-center pl-4'>
                <button
                  onClick={handleClickButtonAddColumn}
                  className='border-2 border-spruce flex font-bold h-12 items-center justify-center mr-4 mt-12 px-3 rounded text-center text-spruce transition-colors duration-100 hover:bg-spruce/5'
                >
                  <span className='mr-2'>
                    <svg height='16' viewBox='0 0 448 512'>
                      <path
                        d='M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  <span>Add column</span>
                </button>
              </div>
            </div>
          </div>
          <div className='pt-4'>
            <button
              onClick={handleClickButtonAddRow}
              className='border-2 border-spruce flex font-bold h-12 items-center justify-center ml-12 px-3 rounded text-center text-spruce transition-colors duration-100 hover:bg-spruce/5'
            >
              <span className='mr-2'>
                <svg height='16' viewBox='0 0 448 512'>
                  <path
                    d='M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <span>Add row</span>
            </button>
          </div>
        </div>
        <div className='mb-20'>
          <div className='bg-black break-all font-mono mb-5 p-4 rounded text-sm text-white w-full'>
            {JSON.stringify(table)}
          </div>
          <button
            onClick={handleClickButtonCopy}
            className='border-2 border-spruce flex font-bold h-12 items-center justify-center mx-auto px-3 rounded text-center text-spruce transition-colors duration-100 hover:bg-spruce/5'
          >
            <span className='mr-3'>
              <svg height='16' viewBox='0 0 512 512'>
                <path
                  d='M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>{clipboardString}</span>
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default NutritionTableGeneratorPage;
